import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import MicIcon from '@mui/icons-material/Mic'
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'

import {
  AUDIO,
  IMAGE,
  VIDEO,
  QuestionTypeType,
} from 'common/constants/questionTypes'

import styles from './styles'

type Props = {
  attachment: string
  type: QuestionTypeType
  withoutPhotoswipe?: boolean
  children?: ReactNode
}

export default function Preview({
  attachment,
  type,
  withoutPhotoswipe,
  children,
}: Props) {
  return (
    <Box
      component={withoutPhotoswipe ? 'div' : 'a'}
      href={attachment}
      data-pswp-width={1000}
      data-pswp-height={1000}
      data-pswp-type={type}
      target="_blank"
      rel="noreferrer"
      sx={styles.item}
    >
      {type === IMAGE && (
        <img src={attachment} alt="" width="100%" height="100%" />
      )}
      {type === AUDIO && <MicIcon sx={styles.icon} />}
      {type === VIDEO && <SmartDisplayIcon sx={styles.icon} />}
      {children}
    </Box>
  )
}
