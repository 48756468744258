import { lighten } from '@mui/material/styles'

import variables from 'assets/styles/_variables.module.scss'

const styles = {
  item: {
    position: 'relative',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: lighten(variables.primaryMain, 0.5),
    borderRadius: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'divider',
    overflow: 'hidden',
    '&:hover::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    img: {
      objectFit: 'cover',
    },
  },
  icon: {
    fontSize: '30px',
    color: 'white',
  },
}

export default styles
