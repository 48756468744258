export const TEXT = 'text'
export const IMAGE = 'image'
export const AUDIO = 'audio'
export const VIDEO = 'video'
export const SELECT = 'select'
export const MULTI_SELECT = 'multi_select'
export const DATE_PICKER = 'date'

export type QuestionTypeType =
  | typeof TEXT
  | typeof IMAGE
  | typeof AUDIO
  | typeof VIDEO
  | typeof SELECT
  | typeof MULTI_SELECT
  | typeof DATE_PICKER
